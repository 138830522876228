import $ from "jquery";
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';
import msg from "./contents.js";
import 'lightbox2/dist/css/lightbox.min.css';
import lightbox from 'lightbox2';

/*$(() => {
  console.log(msg);
});*/

// Fixed Navigation

$(window).scroll(function(){
  let $scrollTop = $(window).scrollTop();
  let $elementOffset = $('[data-main-container]').offset().top;
  const $navHeight = $('[data-nav-container]').height();

  let distance = ($elementOffset - $scrollTop);
  const $navContainer = $('[data-nav-container]');
  if(distance < $navHeight ){
    $navContainer.addClass('fixed');
  } else {
    $navContainer.removeClass('fixed');
  }

});

$(document).ready(function(){
  let $scrollTop = $(window).scrollTop();
  let $elementOffset = $('[data-main-container]').offset().top;
  const $navHeight = $('[data-nav-container]').height();

  let distance = ($elementOffset - $scrollTop);
  const $navContainer = $('[data-nav-container]');
  if(distance < $navHeight ){
    $navContainer.addClass('fixed');
  } else {
    $navContainer.removeClass('fixed');
  }

})

// current section
var navBreakpoint = window.matchMedia( '(min-width: 768px)' );

$(window).on('resize orientationchange', function(){
  navBreakpoint = window.matchMedia( '(min-width: 768px)' );
});

$(document).ready(function () {

  $(document).on("scroll", onScroll);
  
  //smoothscroll
  $('a[href^="#"]').on('click', function (e) {
    if ( navBreakpoint.matches === true ) {
      e.preventDefault();
      $(document).off("scroll");
      
      $('a').each(function () {
          $(this).removeClass('active');
      })
      $(this).addClass('active');
    
      var target = this.hash;
      var $target = $(target);
      $('html, body').stop().animate({
          'scrollTop': $target.offset().top+2
      }, 500, 'swing', function () {
          window.location.hash = target;
          $(document).on("scroll", onScroll);
      });
    }
  });
});



  function onScroll(event){
  var scrollPos = $(document).scrollTop();
  var navAnker =  $('.main-nav.home a')
  navAnker.each(function () {
      var currLink = $(this);
      var refElement = $(currLink.attr("href"));
      var refElementPosition = refElement.position().top;
      var refElementHeight = refElement.height();
      if (refElementPosition <= scrollPos && refElementPosition + refElementHeight > scrollPos) {
        navAnker.removeClass("active");
          currLink.addClass("active");
      }
      else{
          currLink.removeClass("active");
      }
    });
  }


// Mobile Navigation

const $mobileNavButton = $('[data-mobile-nav-open]');
const $mobileNav = $('[data-mobile-nav]');
const $mobileNavClose = $('[data-mobile-nav-close]');

$mobileNavButton.on('click', function() {
  $mobileNav.fadeIn(600);
});
$mobileNavClose.on('click', function() {
  $mobileNav.fadeOut(800);
});

// Mobile Slider

(function() {

  'use strict';

  // breakpoint where swiper will be destroyed
  // and switches to a dual-column layout
  const breakpoint = window.matchMedia( '(min-width: 768px)' );

  // keep track of swiper instances to destroy later
  let mySwiper;

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  const breakpointChecker = function() {

    // if larger viewport and multi-row layout needed
    if ( breakpoint.matches === true ) {

      // clean up old instances and inline styles when available
	  if ( mySwiper !== undefined ) mySwiper.destroy( true, true );

	  // or/and do nothing
	  return;

      // else if a small viewport and single column layout needed
      } else if ( breakpoint.matches === false ) {

        // fire small viewport version of swiper
        return enableSwiper();

      }

  };
  
  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  const enableSwiper = function() {

    mySwiper = new Swiper ('.swiper-container', {

      loop: true,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      
      //slidesPerView: 1,

      //centeredSlides: true,

      a11y: true,
      keyboardControl: true,
      grabCursor: true,

      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },

    });

  };

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  // keep an eye on viewport size changes
  breakpoint.addListener(breakpointChecker);

  // kickstart
  breakpointChecker();



})(); /* IIFE end */


// Aktuelles Jahr

const heute = new Date().getFullYear();
$('.copyright .year').html(heute);

// Lightbox

lightbox.option({
  'resizeDuration': 800,
  'wrapAround': true,
  'showImageNumberLabel': false,
  'fadeDuration': 800
})

$(function()
{
    function after_form_submitted(data) 
    {
        if(data.result == 'success')
        {
            $('form#reused_form').hide();
            $('#success_message').show();
            $('#error_message').hide();
        }
        else
        {
            $('#error_message').append('<ul></ul>');

            $.each(data.errors,function(key,val)
            {
                $('#error_message ul').append('<li>'+key+':'+val+'</li>');
            });
            $('#success_message').hide();
            $('#error_message').show();

            //reverse the response on the button
            $('button[type="button"]', $form).each(function()
            {
                var $btn_two = $(this);
                var label = $btn_two.prop('orig_label');
                if(label)
                {
                    $btn_two.prop('type','submit' ); 
                    $btn_two.text(label);
                    $btn_two.prop('orig_label','');
                }
            });
            
        }//else
    }

	$('#reused_form').on('submit', function(e)
      {
        e.preventDefault();

        var $form = $(this);
        
        //show some response on the button
        $('button[type="submit"]', $form).each(function()
        {
            var $btn = $(this);
            $btn.prop('type','button' ); 
            $btn.prop('orig_label',$btn.text());
            $btn.text('Senden ...');
        });
        
        $.ajax({
            type: "POST",
            url: "/handler.php",
            data: $form.serialize(),
            success: after_form_submitted,
            dataType: "json" 
        });        
        
      });	
});